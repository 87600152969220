import React, { useState } from "react";
import InputWithLabel from "./InputWithLabel";
import Button from "../Button/Button";
import Input from "../../custom/Input";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { submitOrder } from "../../../redux/actions/product.action";

import { getDashboard } from "../../../redux/actions/dashboard.action";
import { useFormik } from "formik";
import { productSummarySchama } from "../../../config/schema";

const ProductSummry = ({ onClose, data, row, active }) => {
  const totalQuantity = data.products
    .filter((item) => item.status == "accepted")
    .reduce((acc, current) => acc + current.quantity, 0);
  const totalCost = data.products
    .filter((item) => item.status == "accepted")
    .reduce((acc, current) => acc + current.quantity * current.price, 0);
  const totalServiceCharge = data.products
    .filter((item) => item.status == "accepted")
    .reduce((acc, current) => acc + current.service_charge, 0);

  const formik = useFormik({
    initialValues: {
      cargo: 0,
      air_cargo: 0,
    },
    validationSchema: productSummarySchama,
    onSubmit: (values) => {
      dispatch(
        submitOrder({
          user_id: localStorage.getItem("user_id"),
          order_id: row._id,
          cargo: values?.cargo,
          air_cargo: values?.air_cargo,
        })
      )
        .unwrap()
        .then(() => {
          onClose();
          dispatch(
            getDashboard({
              user_id: localStorage.getItem("user_id"),
              page: 1,
              limit: 5,
              search: "",
            })
          );
        })
        .catch(() => {});
    },
  });
  const nationalCharge = 0;
  const dispatch = useDispatch();
  const [cargo, setCargo] = useState(0);
  const [airCargo, setAirCargo] = useState(0);
  const handleSubmitOrder = () => {
    if (cargo != 0 && !cargo) {
      toast.error("Cargo is required");
      return 0;
    }
    if (airCargo != 0 && !cargo) {
      toast.error("Air cargo is required");
      return 0;
    }
  };

  return (
    <div>
      <p className="text-lg font-semibold">{row?.username}</p>
      <p className="text-base font-semibold text-textColor">{row?.city}</p>
      <table className="table-auto w-full mt-5">
        <thead className="bg-[#F6F6F6] ">
          <tr className="text-textColor text-sm  font-medium  ">
            <th className="text-sm font-medium py-3 text-start px-4">Item</th>
            <th className="text-sm font-medium py-3 text-end ">Quantity</th>
            <th className="text-sm font-medium py-3 text-end pr-4">
              Price Per Qty
            </th>
          </tr>
        </thead>
        <tbody>
          {data.products
            .filter((item) => item.status == "accepted")
            .map((item, index) => (
              <tr key={index} className="text-black text-sm text-center ">
                <td className="text-sm font-semibold py-3 text-start px-4">
                  {item.product_name}
                </td>
                <td className="text-sm font-semibold py-3 pl-6 text-center">
                  {item?.quantity}
                </td>
                <td className="text-sm font-semibold pr-4 text-end">
                  IQD {item?.price}
                </td>
              </tr>
            ))}
          <tr className="text-black text-sm text-center border-t border-[#F1F5F9] ">
            <td className="text-base font-medium text-[#808080] py-3 text-start px-4 ">
              Subtotal
            </td>
            <td className="text-base font-medium text-[#808080] py-3 pl-6 text-center">
              {totalQuantity}
            </td>
            <td className="text-base font-medium text-[#808080] pr-4 text-end">
              IQD {totalCost}
            </td>
          </tr>
          <tr className="text-black text-sm text-center border-b border-[#F1F5F9]">
            <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
              Service Charges
            </td>
            <td className="text-base font-medium text-[#808080] py-3 pl-6  text-end"></td>
            <td className="text-base font-medium text-[#808080] pr-4 text-end">
              IQD {totalServiceCharge}
            </td>
          </tr>
          <tr className="text-black text-sm text-center border-b border-[#F1F5F9]">
            <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
              National Charges
            </td>
            <td className="text-base font-medium text-[#808080] py-3 pl-6 text-end"></td>
            <td className="text-base font-medium text-[#808080] pr-4 text-end">
              IQD {nationalCharge}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="py-4">
        <p className="text-xl font-semibold">Transportation Charges</p>
      </div>
      <form className="space-y-4" onSubmit={formik.handleSubmit}>
        <InputWithLabel
          // value={cargo}
          name="cargo"
          onChange={formik.handleChange}
          error={formik.errors.cargo}
          touched={formik.touched.cargo}
          onBlur={formik.handleBlur}
          value={formik.values.cargo}
          label="Cargo"
          placeholder="Cargo"
          type={"number"}
        />
        <InputWithLabel
          name="air_cargo"
          // value={airCargo}
          onChange={formik.handleChange}
          error={formik.errors.air_cargo}
          touched={formik.touched.air_cargo}
          onBlur={formik.handleBlur}
          value={formik.values.air_cargo}
          type={"number"}
          label="Air Cargo"
          placeholder="Air Cargo"
        />
        <div className="flex justify-between w-full items-center border-t border-b py-3 my-5 border-[#F1F5F9]">
          <p className="text-base font-bold">Total</p>
          <p className="text-base font-bold">
            IQD {totalCost + totalServiceCharge + nationalCharge}
          </p>
        </div>
        <div className="pb-3">
          <button
            type="submit"
            className="bg-[#383F90] text-white text-base font-semibold py-3 rounded-[12px] w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductSummry;
