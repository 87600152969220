import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
import { cn } from "../components/lib/utlis";
import SearchBar from "../components/common/Form/SearchBar";
import Layout from "../components/common/Layouts/Layout";
import { IoMdSend } from "react-icons/io";
import ViewDeliveryModal from "../components/common/Modal/ViewDeliveryModal";
import ViewOrdersModal from "../components/common/Modal/ViewOrdersModal";

const BranchChat = () => {
  const adminId = localStorage.getItem("admin_id");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [chats, setChats] = useState([]);
  const [chatValue, setChatValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const chatContainer = useRef(null);
  const socketRef = useRef();
  const [openModal, setOpenModal] = useState({ open: false, data: null });

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    const socket = socketRef.current;
    socket.on("connect", () => console.log("Connected"));
    socket.on("setChatUserlist", (user) => {
      setUsers(user.resData);

      const userId =
        searchParams.get("user_id") || user.resData[0]?.user_detail._id;
      if (userId) {
        const selectedUser = user.resData.find(
          (user) => user.user_detail._id === userId
        );
        if (selectedUser) {
          setSelectedUser(selectedUser);
          setSearchParams({ user_id: userId });
          socket.emit("getMessageList", adminId, userId);
        }
      }
    });
    socket.on("setMessageList", (chat) => {
      setChats(chat.resData.reverse());
    });
    socket.on("setNewMessage", (chat) => {
      setChats((prevState) => [...prevState, chat.resData]);
    });
    socket.emit("socketJoin", adminId);
    socket.emit("getChatUserlist", adminId);
    return () => {
      socket.off("connect");
      socket.off("setChatUserlist");
      socket.off("setMessageList");
      socket.off("setNewMessage");
      socket.disconnect();
    };
  }, [searchParams]);

  useEffect(() => {
    if (!chatContainer.current || !chats.length) return;
    chatContainer.current.scrollTo({
      top: chatContainer.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chats]);

  const sendMessage = () => {
    const socket = socketRef.current;
    const userId = selectedUser?.user_detail._id;
    if (userId && chatValue.trim()) {
      socket.emit(
        "sendMessage",
        chatValue,
        adminId,
        userId,
        "text",
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      const newList = chats;
      newList.push({
        _id: Date.now(),
        msg: chatValue,
        msg_type: "text",
        receiver: { _id: userId },
        sender: { _id: adminId, name: "A" },

        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setChats(newList);
      // Optionally: Emit an event to get the updated message list
      // socket.emit("getMessageList", adminId, userId);
      // socket.on("setMessageList", (chat) => {
      //   setChats(chat.resData);
      // });

      // Clear input field
      setChatValue("");
    }
  };

  const handleUserClick = (user) => {
    const socket = socketRef.current;
    const userId = user.user_detail._id;

    setSelectedUser(user);
    setSearchParams({ user_id: userId });
    socket.emit("getMessageList", adminId, userId);
  };

  const getInitials = (name) => {
    return name
      ?.split(" ")
      ?.filter(Boolean) // Remove any extra spaces
      ?.map((word) => word[0].toUpperCase()) // Get first letter and convert to uppercase
      ?.join("");
  };

  const groupedChats =
    chats &&
    chats?.reduce((acc, chat) => {
      const date = moment(chat.createdAt).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(chat);
      return acc;
    }, {});
  return (
    <>
      <Layout title="Chat" role="branchAdmin">
        <div className="h-full flex relative gap-5 mt-3 overflow-hidden">
          <div className="h-full flex flex-col bg-white absolute left-0 w-[400px] rounded-lg overflow-hidden">
            <div className="px-4 pt-4 pb-4">
              <SearchBar type="text" placeholder="Search..." />
            </div>
            <div className="h-full overflow-y-auto">
              {users.length ? (
                users.map((user) => (
                  <button
                    key={user._id}
                    type="button"
                    className={cn(
                      "w-full px-4 flex gap-3 items-center py-4 border-b border-[#F1F1F3] transition-colors",
                      selectedUser?.user_detail?._id === user.user_detail._id
                        ? "bg-slate-100"
                        : "hover:bg-slate-100"
                    )}
                    onClick={() => handleUserClick(user)}
                  >
                    <div className="w-10 h-10 rounded-full">
                      {user.user_detail.profile ? (
                        <img
                          src={user.user_detail.profile}
                          alt="profile name"
                        />
                      ) : (
                        <div className="h-full bg-mainBG text-white font-semibold rounded-full flex justify-center items-center">
                          <p>{getInitials(user.user_detail.name)}</p>
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="text-sm font-semibold text-start">
                        {user.user_detail.name}
                      </p>
                      <p className="text-xs font-normal text-start text-[#808080]">
                        {user.msg}
                      </p>
                    </div>
                  </button>
                ))
              ) : (
                <div className="h-full flex justify-center items-center">
                  <p className="text-3xl text-slate-200 font-medium">
                    Loading...
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="absolute right-0 w-[calc(100%-420px)] bg-white h-full overflow-hidden rounded-lg flex flex-col">
            <div className="w-full h-full flex flex-col flex-1 overflow-hidden">
              {selectedUser ? (
                <>
                  <div className="p-3 bg-gray-200 flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="w-10 h-10 rounded-full">
                        {selectedUser.user_detail.profile ? (
                          <img
                            src={selectedUser.user_detail.profile}
                            alt="profile name"
                          />
                        ) : (
                          <div className="h-full bg-mainBG text-white font-semibold rounded-full flex justify-center items-center">
                            {getInitials(selectedUser.user_detail.name)}
                          </div>
                        )}
                      </div>
                      <div className="ml-4">
                        <p className="text-xl font-semibold">
                          {selectedUser.user_detail.name}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 overflow-hidden bg-[#F8FAFC] rounded-lg mx-4 mt-5 mb-5 flex flex-col noScrollbar">
                    <div
                      ref={chatContainer}
                      className="h-full noScrollbar px-4 pt-4 overflow-y-auto"
                    >
                      {Object.keys(groupedChats).length ? (
                        Object.keys(groupedChats).map((date) => (
                          <div key={date}>
                            <div className="text-center text-sm text-gray-500 mb-2">
                              {moment(date).format("DD MMMM YYYY")}
                            </div>
                            {groupedChats[date].map((chat) => {
                              if (!chat || !chat._id) return null; // Check if chat or chat._id is undefined

                              const isAdminSender =
                                chat.sender?._id === adminId;

                              return isAdminSender ? (
                                <div
                                  key={chat._id}
                                  className="flex flex-col items-end mb-5 space-y-1"
                                >
                                  <div className="flex gap-3 justify-end items-center">
                                    <div className="rounded-t-2xl rounded-l-2xl py-3.5 px-3 bg-white">
                                      <p className="text-sm mt-1">{chat.msg}</p>
                                    </div>

                                    <div className="w-10 h-10 rounded-full">
                                      {chat.sender?.profile ? (
                                        <img
                                          src={chat.sender.profile}
                                          alt={chat._id}
                                        />
                                      ) : (
                                        <div className="h-full bg-mainBG text-white font-semibold rounded-full flex justify-center items-center">
                                          <p>{getInitials(chat.sender.name)}</p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-sm text-[#808080] font-medium">
                                      {moment(chat.createdAt).format("hh:mm A")}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex flex-col items-start  mb-5 space-y-1">
                                  <div
                                    key={chat._id}
                                    className="flex gap-2 items-center"
                                  >
                                    <div className="w-10 h-10 rounded-full">
                                      {chat.receiver?.profile ? (
                                        <img
                                          src={chat.receiver.profile}
                                          alt={chat._id}
                                        />
                                      ) : (
                                        <div className="h-full bg-mainBG text-white font-semibold rounded-full flex justify-center items-center">
                                          <p>{getInitials(chat.sender.name)}</p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="rounded-b-2xl rounded-r-2xl py-3.5 px-3 bg-[#F1F1F3]">
                                      {chat?.msg_type === "link" ? (
                                        <p
                                          className="text-sm mt-1 font-semibold cursor-pointer"
                                          onClick={() => {
                                            setOpenModal({
                                              open: true,
                                              data: {
                                                _id: chat.msg,
                                              },
                                            });
                                          }}
                                        >
                                          {chat.msg}
                                        </p>
                                      ) : (
                                        <p className="text-sm mt-1 ">
                                          {chat.msg}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-sm text-[#808080] font-medium">
                                      {moment(chat.createdAt).format("hh:mm A")}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ))
                      ) : (
                        <div className="h-full flex justify-center items-center">
                          <p className="text-3xl text-slate-200 font-medium">
                            Loading...
                          </p>
                        </div>
                      )}
                      {/* {chats.length ? (
                        chats.map((chat) => {
                          if (!chat || !chat._id) return null; // Check if chat or chat._id is undefined

                          const isAdminSender = chat.sender?._id === adminId;

                          return isAdminSender ? (
                            <div
                              key={chat._id}
                              className="flex gap-3 justify-end items-center mb-5"
                            >
                              <div className="rounded-t-2xl rounded-l-2xl py-3.5 px-3 bg-white">
                                <p className="text-sm mt-1">{chat.msg}</p>
                              </div>
                              <div className="w-10 h-10 rounded-full">
                                {chat.sender?.profile ? (
                                  <img
                                    src={chat.sender.profile}
                                    alt={chat._id}
                                  />
                                ) : (
                                  <div className="h-full bg-mainBG text-white font-semibold rounded-full flex justify-center items-center">
                                    <p>PIKA</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div
                              key={chat._id}
                              className="flex gap-2 items-center mb-5"
                            >
                              <div className="w-10 h-10 rounded-full">
                                {chat.receiver?.profile ? (
                                  <img
                                    src={chat.receiver.profile}
                                    alt={chat._id}
                                  />
                                ) : (
                                  <div className="h-full bg-mainBG text-white font-semibold rounded-full flex justify-center items-center">
                                    <p>PIKA</p>
                                  </div>
                                )}
                              </div>
                              <div className="rounded-b-2xl rounded-r-2xl py-3.5 px-3 bg-[#F1F1F3]">
                                <p className="text-sm mt-1">{chat.msg}</p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="h-full flex justify-center items-center">
                          <p className="text-3xl text-slate-200 font-medium">
                            Loading...
                          </p>
                        </div>
                      )} */}
                    </div>
                    {/* <div className="px-3 py-2  flex gap-3  items-center">
                      <div>
                      <input
                        type="text"
                        placeholder="Write your message"
                        className="w-full p-3.5 bg-white rounded-lg  text-sm"
                        value={chatValue}
                        onChange={(e) => setChatValue(e.target.value)}
                      />
                      <button
                        type="button"
                        onClick={sendMessage}
                        className="bg-primary text-white p-3 rounded-full text-lg"
                      >
                        <IoMdSend className="text-black" />
                      </button>
                      </div>
                    </div> */}
                    <div className="px-4 absolut bottom-18 z-10 py-4 flex items-center">
                      <div className="flex-1 relative">
                        <input
                          className="w-full focus:outline-none  rounded-lg p-3.5 pr-14"
                          type="text"
                          placeholder="Write your message..."
                          value={chatValue}
                          onChange={(e) => setChatValue(e.target.value)}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              sendMessage();
                            }
                          }}
                        />
                        <button
                          type="button"
                          className="absolute right-2 top-1/2 -translate-y-1/2 bg-mainBG rounded-full p-2"
                          onClick={sendMessage}
                        >
                          <IoMdSend className="size-5 text-white" />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex-1 h-full flex justify-center items-center">
                  <p className="text-3xl text-slate-200 font-medium">
                    No User Selected
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {openModal?.open && (
          <ViewDeliveryModal
            openModal={openModal}
            closeModal={() => {
              setOpenModal({ open: false, data: null });
            }}
          />
        )} */}
        <ViewOrdersModal limit={10} data={openModal} setData={setOpenModal} title=""/>
      </Layout>
    </>
  );
};

export default BranchChat;
