import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import InputWithLabel from "../Form/InputWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { addFund } from "../../../redux/actions/auth.action";
import { addFundSchema } from "../../../config/schema";
import { getUserData } from "../../../redux/actions/user.action";

const AddFundModal = ({ openModal, closeModal }) => {
  const dispatch = useDispatch();
  const admin_id = localStorage.getItem("user_id");

  const formik = useFormik({
    initialValues: {
      // app_user_id: openModal?.data?._id,
      amount: 0,
    },
    validationSchema: addFundSchema,
    onSubmit: async (values) => {
      dispatch(
        addFund({
          amount: parseInt(values.amount),
          user_id: admin_id,
          app_user_id: openModal?.data?._id,
        })
      )
        .unwrap()
        .then(() => {
          closeModal();
          dispatch(
            getUserData({
              user_id: localStorage.getItem('user_id'),
              page: 1,
              limit: 5,
              search: "",
            })
          );
        })
        .catch(() => {});
    },
  });

  return (
    <>
      <Dialog open={openModal?.open} onOpenChange={closeModal}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">Add Funds</h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <form onSubmit={formik.handleSubmit}>
              <div className="px-4 pb-4 space-y-4 border-b border-[#F1F5F9]">
                {/* <InputWithLabel
                  name="app_user_id"
                  type="text"
                  placeholder="Enter Unique ID"
                  label="Unique ID"
                  value={formik.values.app_user_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.app_user_id}
                  touched={formik.touched.app_user_id}
                /> */}
                {/* <InputWithLabel
                  type="text"
                  placeholder="Enter Name"
                  label="Name"
                /> */}
                <InputWithLabel
                  type="text"
                  placeholder="Credits"
                  label="Credits"
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.amount}
                  touched={formik.touched.amount}
                />
              </div>
              <div className="py-5 px-4">
                <Button
                  text="Submit"
                  type={true}
                  buttonType="submit"
                  className="py-3 rounded-lg w-full"
                />
              </div>
            </form>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddFundModal;
