import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import SearchBar from "../Form/SearchBar";
import { imageUrl } from "../../../config/url";
import { useDispatch, useSelector } from "react-redux";
import { getDriverData } from "../../../redux/actions/driver.action";

import { assignOrdertoDriver } from "../../../redux/actions/user.action";

const profiles = [
  {
    id: 1,
    image: `${imageUrl}images/ProfileChat.png`,
    name: "Tanner Stafford",
    phone: "+1 123 456 7890",
  },
  {
    id: 2,
    image: `${imageUrl}images/ProfileChat.png`,
    name: "Alex Johnson",
    phone: "+1 987 654 3210",
  },
  {
    id: 3,
    image: `${imageUrl}images/ProfileChat.png`,
    name: "Jamie Smith",
    phone: "+1 555 666 7777",
  },
  {
    id: 4,
    image: `${imageUrl}images/ProfileChat.png`,
    name: "Patricia Brown",
    phone: "+1 444 333 2222",
  },
  {
    id: 5,
    image: `${imageUrl}images/ProfileChat.png`,
    name: "Michael Williams",
    phone: "+1 111 222 3333",
  },
];

const AssignModal = ({ openModal, closeModal }) => {
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const handleCheckboxChange = (driverId) => {
    setSelectedDriverId(driverId);
  };

  const { loading, drivers } = useSelector((state) => state.driver);
  const dispatch = useDispatch();

  useEffect(() => {
    openModal?.open == true &&
      dispatch(
        getDriverData({
          user_id: localStorage.getItem("user_id"),
          search: "",
          page: 1,
          limit: 10,
        })
      );
  }, [openModal]);

  const handleAssignDriver = () => {
    dispatch(
      assignOrdertoDriver({
        user_id: localStorage.getItem("user_id"),
        order_id: openModal.data?._id,
        driver_id: selectedDriverId,
      })
    );
    closeModal();
  };

  return (
    <>
      <Dialog open={openModal?.open} onOpenChange={closeModal}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">
                Assign to Driver
              </h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="px-4 pt-2 pb-4 max-h-[522px] overflow-y-auto noScrollbar ">
              <SearchBar type="text" placeholder="Search..." />
              {drivers.map((profile, index) => (
                <label
                  key={index}
                  className="flex items-center justify-between space-x-3 py-4 cursor-pointer"
                >
                  <div className="flex gap-3 items-center">
                    <img
                      src={profile.profile}
                      alt="Profile"
                      className="w-[48px] h-[48px] rounded-full"
                    />
                    <div className="space-y-1">
                      <h1 className="text-sm font-bold text-[#0F172A] capitalize">
                        {profile.name}
                      </h1>
                      <p className="text-xs font-normal text-[#64748B]">
                        +{profile.ccode} {profile.phone}
                      </p>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    className="border-gray-300 rounded h-5 w-5"
                    checked={selectedDriverId === profile._id}
                    onChange={() => handleCheckboxChange(profile._id)}
                  />
                </label>
              ))}
              <div className="pt-5 border-t border-[#F1F5F9] ">
                <Button
                  text="Assign to Driver"
                  type={true}
                  className="w-full py-3 rounded-lg"
                  onClick={() => {
                    handleAssignDriver();
                  }}
                />
              </div>
            </div>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignModal;
