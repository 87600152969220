import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/Tabel/Tabel";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { options } from "../../data/data";
import Loader from "./Loader";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "./../custom/pagination";
const GenericTable = ({
  data,
  search,
  total,
  params = "",
  params2 = "",
  extra = {},
  columns,
  getData,
  center,
  loading,
  onRowClick,
  status,
  height,
  limitVal = null,
  message,
  closeModal,
  setLimitVal = () => {},
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPage(1);
  }, [limit, search]);
  useEffect(() => {
    let pages = total == 0 ? 0 : Math.ceil(total / limit);
    setPageArray(Array.from({ length: pages }));
  }, [total, limit]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    onRowClick && onRowClick(rowData);
  };
  useEffect(() => {
    dispatch(
      getData({
        page: currentPage,
        limit,
        search,
        user_id: localStorage.getItem("user_id"),
        ...extra,
      })
    );
  }, [search, currentPage, limit, params, status, params2, closeModal]);
  useEffect(() => {
    setLimit(limitVal.value);
  }, [limitVal]);
  return (
    <div className="px-4 py-4  bg-white">
      <div
        className={`w-full max-w-full ${
          height ? height : "min-h-[75vh]"
        }  overflow-x-auto bg-white rounded-xl px-4`}
      >
        <Table className="min-w-full">
          <TableHeader className="bg-[#F8FAFC] sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="border-none">
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className={`py-5 text-[#94A3B8] text-base text-medium ${
                      center ? "text-center" : "text-start"
                    } px-2 first:rounded-l-xl last:rounded-r-xl`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length && !loading ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="border-[#F6F6F6]"
                  onClick={() => handleRowClick(row.original)}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={`py-4 px-4 text-base font-semibold	  ${
                        center ? "text-center" : "text-start"
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-[60vh] justify-center items-center text-center"
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <h1 className="font-semibold text-2xl">{message}</h1>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="px-6 py-2 flex justify-between items-center gap-y-2 sm:gap-y-0 flex-col sm:flex-row bg-white">
        {data.length > 0 ? (
          <p className="w-full text-center sm:text-start text-ternary text-[13px] sm:text-[15px] font-semibold">
            {" "}
            Showing {(currentPage - 1) * limit + 1}-
            {(currentPage - 1) * limit + data.length} on page {currentPage}/
            {pageArray.length}
          </p>
        ) : (
          <p></p>
        )}
        <div className="flex items-center gap-x-4">
          <div>
            <Select
              value={limitVal}
              onChange={(e) => {
                setLimitVal(e);
              }}
              className="w-[80px] focus:outline-none focus:border-none"
              placeholder=""
              options={options}
              menuPlacement="top"
            />
          </div>
          <div>
            <Pagination className="justify-center sm:justify-end">
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    className=""
                    iconStyle="stroke-[#667BA3] stroke-[2.5px] group-hover:stroke-[#F7FCF9]"
                    onClick={() => {
                      if (!(currentPage == 1)) {
                        setCurrentPage((prev) => prev - 1);
                      }
                    }}
                  />
                </PaginationItem>
                {pageArray.map((_, index) => {
                  return (
                    <PaginationItem key={index}>
                      <PaginationLink
                        onClick={() => setCurrentPage(index + 1)}
                        isActive={currentPage == index + 1}
                        className=""
                      >
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                })}
                <PaginationItem>
                  <PaginationNext
                    className=""
                    iconStyle="stroke-[#667BA3] stroke-[2.5px] group-hover:stroke-[#F7FCF9]"
                    onClick={() => {
                      if (!(currentPage == pageArray.length)) {
                        setCurrentPage((prev) => prev + 1);
                      }
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericTable;
