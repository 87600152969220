import * as yup from 'yup'

const LoginSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Enter a valid email'),
    password: yup.string().required('Password is required').min(6, "Minimum 6 character required")
})

const ForgotPasswordSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Enter a valid email')
})

const ResetPasswordSchema = yup.object().shape({
    new_password: yup.string().required('Password is required').min(6, 'Minimum 6 character required'),
    confirm_password: yup.string().required('Password is required').oneOf([yup.ref('new_password'), null], 'password does not match')
})

const DriverSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").email("Enter a valid email"),
    password: yup.string().required("Password is required").min(6, "Minimum 6 character required"),
    // city: yup.string().required("City is required"),
    profile: yup.string().required("Profile image is required"),
    ccode: yup.string().required("Phone code is required"),
    phone: yup.string().required("Phone number is required"),
    ccode: yup.string().required("Code is required"),
    licence_number: yup.string().required("Licence number is required"),
    confirm_password: yup.string().oneOf([yup.ref('password')], "Password should be match").required("Confirm password is required")
})

const addFundSchema = yup.object().shape({
    // app_user_id: yup.string().required("Uniqe Id is required"),
    amount: yup.string().required("Amount is required")
})


const ProductSchema = yup.object().shape({
    category_id: yup.string().required('Category is required'),
    product_name: yup.string().required('Prodcut name is required'),
    price: yup.number().min(1).typeError('Price must be number').required('Price is required')
})

const AddAssignmentSchema = yup.object().shape({
    branch: yup.string().required('Branch is required'),
    name: yup.string().required('Assignment name is required')
})
const ConfirmOrder = yup.object().shape({
    assignment_id: yup.string().required('Assignment is required'),
})

const AddBranchSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    city: yup.string().required('City is required'),
    email: yup.string().required("Email is required").email("Enter a valid email"),
    phone: yup.string().required('Phone is required'),
    ccode: yup.string().required('Code is required'),
    name: yup.string().required('Name is required'),
    password: yup.string().required('Password is required').min(6, 'Minimum 6 character required'),
    confirm_password: yup.string().required('Password is required').oneOf([yup.ref('password'), null], 'password does not match')
})

const productSummarySchama = yup.object().shape({
    cargo: yup.number().required("Cargo charge is required").positive("Cargo charge must be a positive value"),
    air_cargo: yup.number().required("Air cargo is required").positive("Air cargo must be a positive value")
})


export { LoginSchema, AddBranchSchema, ForgotPasswordSchema, ConfirmOrder, ResetPasswordSchema, DriverSchema, addFundSchema, ProductSchema, AddAssignmentSchema, productSummarySchama }
