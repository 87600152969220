import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import InputWithLabel from "../Form/InputWithLabel";
import SelectBox from "../Form/SelectBox";
import Button from "../Button/Button";
import { useFormik } from "formik";
import { DriverSchema } from "../../../config/schema";
import md5 from "md5";
import { useDispatch } from "react-redux";
import {
  addDriverData,
  getDriverData,
} from "../../../redux/actions/driver.action";

const cities = ["New York", "Tokyo", "Toronto", "Ottawa", "Edmonton"];

const AddDriver = ({ openModal, closeModal }) => {
  const dispatch = useDispatch();
  const user_id = localStorage.getItem("user_id");
  const branch_city = localStorage.getItem("city");
  const [profile, setProfile] = useState("");
  const [actualPassword, setActualPassword] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      ccode: "+91",
      phone: "",
      password: "",
      confirm_password: "",
      licence_number: "",
      profile: "",
    },
    validationSchema: DriverSchema,
    onSubmit: async (values) => {
      const fromData = new FormData();
      fromData.append("user_id", user_id);
      fromData.append("profile", values?.profile);
      fromData.append("name", values.name);
      fromData.append("email", values.email);
      fromData.append("ccode", values.ccode);
      fromData.append("phone", values.phone);
      fromData.append("password", md5(values.password));
      fromData.append("city", branch_city);
      fromData.append("licence_number", values.licence_number);
      dispatch(addDriverData(fromData))
        .unwrap()
        .then(() => {
          closeModal();
          formik.resetForm();
          setProfile("");
          dispatch(
            getDriverData({
              user_id: localStorage.getItem("user_id"),
              page: 1,
              limit: 10,
              search: "",
            })
          );
        })
        .catch(() => {});
    },
  });

  const handleChange = (e) => {
    const { value } = e.target;

    // Update the actual password value
    setActualPassword(value);
  };

  const getMaskedValue = () => {
    return "X".repeat(actualPassword.length);
  };

  return (
    <>
      <Dialog open={openModal} onOpenChange={closeModal}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">Add Driver</h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <form onSubmit={formik.handleSubmit}>
              <div className="px-4 h-[500px] overflow-x-auto noScrollbar">
                <p className="text-sm font-semibold text-[#0F172A]">Photo</p>
                <div class="flex w-full flex-col items-start justify-start mt-5">
                  {!profile ? (
                    <label class="w-32 h-32 flex flex-col items-center justify-center  bg-[#F8FAFC] text-blue rounded-lg  cursor-pointer">
                      <IoCloudUploadOutline className="text-2xl font-bolder text-[#2563EB]" />
                      <span class="mt-2 text-xs font-semibold text-[#2563EB]">
                        Upload Image
                      </span>
                      <input
                        type="file"
                        class="hidden"
                        name="profile"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          setProfile(e.target.files[0]);
                          formik.setFieldValue(
                            "profile",
                            e.currentTarget.files[0]
                          );
                        }}
                      />
                    </label>
                  ) : (
                    <img
                      src={URL.createObjectURL(profile)}
                      className="w-[130px] h-[130px] object-cover rounded-md"
                    />
                  )}
                  {formik.touched.profile && formik.errors.profile ? (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.profile}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-4 mt-3 mb-4">
                  <div className="grid grid-cols-2 gap-5">
                    <InputWithLabel
                      name="name"
                      label="Name"
                      placeholder="Name"
                      type={"text"}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      error={formik.errors.name}
                      touched={formik.touched.name}
                    />
                    <InputWithLabel
                      name="email"
                      label="Email"
                      placeholder="Email"
                      type={"text"}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      error={formik.errors.email}
                      touched={formik.touched.email}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <InputWithLabel
                      name="ccode"
                      label="Code"
                      placeholder="+91"
                      type={"text"}
                      value={formik.values.ccode}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      error={formik.errors.ccode}
                      touched={formik.touched.ccode}
                    />
                    <InputWithLabel
                      name="phone"
                      label="Phone Number"
                      placeholder="123 456 7890"
                      type={"text"}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      error={formik.errors.phone}
                      touched={formik.touched.phone}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <InputWithLabel
                      name="licence_number"
                      label="License Number"
                      placeholder="DL 1234567892560"
                      type={"text"}
                      value={formik.values.licence_number}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      error={formik.errors.licence_number}
                      touched={formik.touched.licence_number}
                    />
                    <InputWithLabel
                      name="city"
                      label="City"
                      placeholder="Enter city"
                      type={"text"}
                      value={branch_city}
                      // disabled={true}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      // error={formik.errors.licence_number}
                      // touched={formik.touched.licence_number}
                    />
                    {/* <SelectBox
                      label="City"
                      name="city"
                      defaultOption="Please select"
                      options={cities}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      error={formik.errors.city}
                      touched={formik.touched.city}
                    /> */}
                  </div>
                  <div className="grid grid-cols-2 gap-5 pb-6">
                    {/* <div className="flex flex-col space-y-3  w-full">
                      <label className="text-sm font-semibold text-start">
                        Password
                      </label>
                      <input
                        type="text"
                        className="w-full border py-3 px-3 rounded-xl	border-[#E2E8F0]"
                        placeholder="Enter password"
                        value={getMaskedValue()}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex flex-col space-y-3  w-full">
                      <label className="text-sm font-semibold text-start">
                        Confirm Password
                      </label>
                      <input
                        type="text"
                        className="w-full border py-3 px-3 rounded-xl	border-[#E2E8F0]"
                        placeholder="Enter password"
                        value={getMaskedValue()}
                        onChange={handleChange}
                      />
                    </div> */}
                    <InputWithLabel
                      name="password"
                      label="Password"
                      placeholder="Enter Password"
                      type={"password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.password}
                      touched={formik.touched.password}
                    />
                    <InputWithLabel
                      name="confirm_password"
                      label="Confirm Password"
                      placeholder="Enter Confirm Password"
                      type={"password"}
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.confirm_password}
                      touched={formik.touched.confirm_password}
                    />
                  </div>
                  <Button
                    text="Add"
                    buttonType="submit"
                    className="py-3 rounded-lg w-full"
                    type={true}
                    onClick={() => {
                      
                    }}
                  />
                </div>
              </div>
            </form>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddDriver;
