import { Controller, useFormContext } from "react-hook-form";

const InputField = ({
  label,
  name,
  placeholder,
  type,
  className = "",
  ...others
}) => {
  const { control, formState } = useFormContext();
  return (
    <div>
      <div className="flex flex-col space-y-4">
        <label className="text-sm font-semibold text-[#0F172A]">{label}</label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              type={type}
              {...field}
              placeholder={placeholder}
              className={`w-full border py-3 px-3 rounded-xl text-[#808080] placeholder:text-[#808080]	border-[#E2E8F0] outline-none ${className}`}
              {...others}
            />
          )}
        />
      </div>
      {formState.errors?.[name]?.message && (
        <div className="flex text-red-500 w-full text-sm justify-start px-3 mt-2">
          <span className="text-left">{formState.errors?.[name]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default InputField;
