import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const TextAreaField = ({ label, placeholder, name }) => {
  const { control } = useFormContext()
  return (
    <div className="flex flex-col space-y-4">
      <label className="text-sm font-semibold">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <textarea
            {...field}
            placeholder={placeholder}
            className="w-full border py-3 px-3 rounded-xl h-[140px]	border-[#E2E8F0]"
          />
        )}
      />
    </div>
  );
};

export default TextAreaField;
