import React, { useEffect, useState } from "react";
import InputWithLabel from "./InputWithLabel";
import TextArea from "./TextArea";
import Button from "../Button/Button";
import InputField from "../../custom/InputField";
import FormProvider from "./FormProvider";
import { useForm } from "react-hook-form";
import TextAreaField from "./TextAreaField";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../redux/actions/category.action";

import Select from "../../custom/Select";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductSchema } from "../../../config/schema";
import { rejectOrApprovedProduct } from "../../../redux/actions/product.action";
import { getReview } from "../../../redux/actions/review.action";

const ProductFrom = ({ active, data, row }) => {
  const details = active == "Summary" ? {} : data.products[active];
  const [status, setStatus] = useState("")
  const [category, setCategory] = useState([])
  const methods = useForm({
    defaultValues:{
      category_id:""
    },
    resolver: yupResolver(ProductSchema)
  })
  const dispatch = useDispatch()

  const { categoryData, loading } = useSelector((state) => state.category)

  useEffect(() => {
    if (categoryData.length > 0) {
      setCategory(categoryData.map((item) => ({
        value: item._id,
        label: item.name
      })))
    }
  }, [categoryData])

  useEffect(() => {
    dispatch(getCategory({
      user_id: localStorage.getItem('user_id')
    }))
  }, [])
  const { handleSubmit, reset } = methods

  useEffect(() => {
    if (Object.keys(details).length > 0) {

      reset({
        quantity: details?.quantity,
        url: details?.url,
        city: data?.order?.address?.city,
        product_name: details?.product_name,
        price: details?.price,
        service_charge: details?.service_charge,
        note: details?.note,
        name: row?.username,
        category_id:details?.category?._id
      })
    }

  }, [active])


  const onSubmit = (values) => {
   
    dispatch(rejectOrApprovedProduct({
      user_id: localStorage.getItem('user_id'),
      product_id: details?._id,
      product_name: values.product_name,
      price: values.price,
      service_charge: values.service_charge,
      category_id: values.category_id,
      note: values.note,
      action: status
    })).unwrap().then(()=>{
      dispatch(getReview({
        user_id:localStorage.getItem('user_id'),
        order_id:row?._id
      }))
    }).catch(()=>{

    })
  }
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <InputField label="Name" name={'name'} placeholder="Name" type={"text"} />
          <InputField label="Quantity" name="quantity" placeholder="Quantity" type={"number"} disabled={true} />
          <InputField label="URL" name="url" placeholder="URL" type={"text"} disabled={true} />
          <InputField label="City" name="city" placeholder="City" type={"text"} disabled={true} />
          <InputField
            label="Product Name"
            placeholder="Product Name"
            type={"text"}
            name="product_name"
          />
          <InputField label="Price" name={"price"} placeholder="Price" type={"number"} />
          <InputField
            label="Service Charges"
            name="service_charge"
            placeholder="Service Charges"
            type={"text"}
          />

          <TextAreaField name="note" label="Note" placeholder="Add Message...." />
          <Select disabled={details.status != "pending"} placeholder={"Select category"} label={"Category"} name="category_id" options={category} />
          {details?.status == "pending"  ? <div className="px-3 flex gap-4 w-full  py-5">
            <button onClick={() => { setStatus("rejected") }} type="submit" className="text-white bg-[#808080] py-3 font-semibold text-base rounded-xl w-full">
              Reject
            </button>
            <Button
              text="Accept"
              buttonType="submit"
              type={true}
              onClick={() => { setStatus("accepted") }}
              className="py-3 font-semibold text-base rounded-xl w-full"
            />
          </div> :
            <div className="px-3 flex justify-end gap-4 w-full  py-7">
              {details?.status == "accepted" ? <div className="font-semibold text-base text-[#2fc78f] capitalize">{details.status}</div> : <div className="font-semibold text-base capitalize text-[#ed4f4f]">{details.status}</div>}
            </div>}
        </div>
      </FormProvider>
    </>
  );
};

export default ProductFrom;
