import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import { imageUrl } from "../../../config/url";
import { useDispatch } from "react-redux";
import {
  deleteDriverData,
  getDriverData,
} from "../../../redux/actions/driver.action";

const DeleteDriver = ({ openModal, closeModal }) => {
  const dispatch = useDispatch();
  const handleDeleteDriver = async () => {
    dispatch(
      deleteDriverData({ user_id: localStorage.getItem('user_id'), driver_id: openModal?.data?._id })
    )
      .unwrap()
      .then(() => {
        closeModal();
        dispatch(
          getDriverData({
            user_id: localStorage.getItem('user_id'),
            page: 1,
            limit: 5,
            search: "",
          })
        );
      })
      .catch(() => {});
  };
  return (
    <>
      <Dialog open={openModal?.open} onOpenChange={closeModal}>
        <DialogContent className="px-5 pb-5">
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold"></h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="py-3 flex flex-col justify-center items-center space-y-2 w-full">
              <img
                src={`${imageUrl}images/deleteBranch.png`}
                alt="delete branch"
              />
              <p className="text-xl font-semibold">Delete Driver</p>
              <p className="text-center text-lg font-medium text-[#808080] w-[206px]">
                Are you sure you want to delete driver?
              </p>
              <div className="grid grid-cols-2 gap-8 w-full px-12 pt-5 border-t border-[#F1F5F9]">
                <button
                  className="bg-[#808080] text-white text-base font-bold py-3 rounded-lg w-full"
                  onClick={() => {
                    handleDeleteDriver();
                  }}
                >
                  Yes
                </button>
                <Button
                  type={true}
                  text="No"
                  className="py-3 rounded-lg  text-base font-bold"
                  onClick={closeModal}
                />
              </div>
            </div>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteDriver;
